import React from 'react';
import AsyncOpenHelpPanelLinkThroughEventListener from '@atlassian/jira-help-panel-button/src/async.tsx';
import AsyncLegacyTeamProfileCard from '@atlassian/jira-profilecard-next/src/ui/team-profilecard-legacy/async.tsx';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { RouteContext } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';

type Props = RouteContext;

export const LegacyPageWithTeamProfileCardView = (props: Props) => (
	<>
		<LegacyPage {...props} />

		<AsyncOpenHelpPanelLinkThroughEventListener />

		<AsyncLegacyTeamProfileCard
			TenantContextSubscriber={
				fg('empanada_nin_concurrent_mode_fixes') ? undefined : TenantContextSubscriber
			}
		/>
	</>
);
