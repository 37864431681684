/* index.tsx generated by @compiled/babel-plugin v0.35.0 */
import { forwardRef } from 'react';
import { ax, ix } from "@compiled/react/runtime";
import React, { useCallback, useState, useEffect } from 'react';
import { di } from 'react-magnetic-di';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import WarningIcon from '@atlaskit/icon/glyph/warning';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalBody, ModalTitle, ModalHeader, ModalFooter } from '@atlaskit/modal-dialog';
import { Inline, Box, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import { colors } from '@atlaskit/theme';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW, ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { buildEditWorkflowJSPUrl, getLegacyEditorLink, getNewEditorLink } from '@atlassian/jira-project-configuration-commons/src/model/utils.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { NEW_EDITOR, LEGACY_EDITOR, type EditorType, WorkflowPublicationStatus } from '../../common/types.tsx';
import { useChangePreferenceFlag } from '../../controllers/change-preference-flag/index.tsx';
import { useNewWorkflowEditorPreference } from '../../controllers/editor-preference/index.tsx';
import { useOptOutFeedbackDialog } from '../../controllers/opt-out-feedback/index.tsx';
import { changeDefaultWorkflowEditorToLegacy, changeDefaultWorkflowEditorToNew } from '../../services/set-workflow-editor-preference/index.tsx';
import { setShowNewEditorSuccessFlag, setShowOldEditorSuccessFlag } from '../../services/update-preference-session/index.tsx';
import newEditorImage from './assets/newEditorImage.very-specific-illu.png';
import oldEditorImage from './assets/oldEditorImage.very-specific-illu.png';
import messages from './messages.tsx'; // remove when cleaning jira-workflow-editor-analytics_53yss
// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax

export enum WarningType {
  None,
  Dirty,
  Draft,
}
type Props = {
  onClose: () => void;
  onOpenDefaultEditorDialog?: () => void;
  workflowName: string;
  workflowId: string | undefined;
  projectType?: ProjectType;
  workflowPublicationStatus?: WorkflowPublicationStatus;
  shouldRedirectToDraftMode?: boolean;
};
const DefaultWorkflowEditorDialogInner = ({
  workflowName,
  projectType,
  onClose,
  onOpenDefaultEditorDialog,
  workflowId,
  workflowPublicationStatus = WorkflowPublicationStatus.None,
  shouldRedirectToDraftMode = true
}: Props) => {
  di(window);
  const {
    formatMessage
  } = useIntl();
  const [selectedWorkflowEditor, setSelectedWorkflowEditor] = useState<EditorType | null | undefined>(null);
  const [{
    route
  }] = useRouter();
  const {
    userPreference
  } = useNewWorkflowEditorPreference();
  const {
    showNewEditorSuccessFlag,
    showOldEditorSuccessFlag,
    showErrorFlag
  } = useChangePreferenceFlag();
  useEffect(() => {
    setSelectedWorkflowEditor(userPreference);
  }, [userPreference, setSelectedWorkflowEditor]);
  const redirectToLegacyEditorAndShowSuccessFlag = useCallback(() => {
    if (route?.name === ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW) {
      showOldEditorSuccessFlag(onOpenDefaultEditorDialog);
    } else {
      // set flag to show success flag in session storage when user is redirected to legacy editor

      setShowOldEditorSuccessFlag(); // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage

      window.location.assign(shouldRedirectToDraftMode ? buildEditWorkflowJSPUrl(workflowName) : getLegacyEditorLink(workflowName));
    }
  }, [route?.name, shouldRedirectToDraftMode, showOldEditorSuccessFlag, workflowName, onOpenDefaultEditorDialog]);
  const redirectToNewEditorAndShowSuccessFlag = useCallback(() => {
    if (route?.name === ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT) {
      showNewEditorSuccessFlag();
    } else {
      // set flag to show success flag in session storage when user is redirected to new editor

      setShowNewEditorSuccessFlag();
      route?.name !== ROUTE_NAMES_GLOBAL_SETTINGS_ISSUE_WORKFLOW_EDIT && workflowId &&
      // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage

      window.location.assign(getNewEditorLink(workflowId));
    }
  }, [route?.name, showNewEditorSuccessFlag, workflowId]);
  const {
    renderFeedbackDialog
  } = useOptOutFeedbackDialog({
    projectType,
    onClose: redirectToLegacyEditorAndShowSuccessFlag
  });
  const onSelectNew = () => setSelectedWorkflowEditor(NEW_EDITOR);
  const onSelectOld = () => setSelectedWorkflowEditor(LEGACY_EDITOR);
  const onConfirmButtonClicked = useCallback(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  async (_: any, analyticsEvent: UIAnalyticsEvent) => {
    try {
      if (selectedWorkflowEditor === LEGACY_EDITOR) {
        await changeDefaultWorkflowEditorToLegacy(analyticsEvent, workflowPublicationStatus);
        renderFeedbackDialog();
      }
      if (selectedWorkflowEditor === NEW_EDITOR) {
        await changeDefaultWorkflowEditorToNew(analyticsEvent, workflowPublicationStatus);
        redirectToNewEditorAndShowSuccessFlag();
      }
      onClose();
    } catch (error) {
      showErrorFlag();
    }
  }, [selectedWorkflowEditor, onClose, renderFeedbackDialog, redirectToNewEditorAndShowSuccessFlag, showErrorFlag, workflowPublicationStatus]);
  const link = 'https://support.atlassian.com/jira-service-management-cloud/docs/using-the-new-workflow-editor/';
  const learnMoreLink = formatMessage(messages.learnMoreLink, {
    a: (chunks: React.ReactNode) => <a href={link} target="_blank">
				{chunks}
			</a>
  });
  const shouldShowWarningMessage = workflowPublicationStatus === WorkflowPublicationStatus.Draft || workflowPublicationStatus === WorkflowPublicationStatus.Dirty;
  return <ShortcutScope>
			<ModalDialog onClose={onClose} width="medium" shouldCloseOnOverlayClick={false}>
				<ModalHeader>
					<ModalTitle testId="workflow-editor-preference-settings.ui.default-workflow-editor-dialog.title">
						{formatMessage(messages.title)}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormattedMessage {...messages.body} />
					<Info>
						<span>{learnMoreLink}</span>
					</Info>
					<Instruction>{formatMessage(messages.instruction)}</Instruction>
					<Inline space="space.100">
						<Box xcss={boxStyles} onClick={onSelectNew} backgroundColor={selectedWorkflowEditor === NEW_EDITOR ? 'color.background.input.hovered' : 'color.background.neutral.subtle'}>
							<Inline alignBlock="center" testId="workflow-editor-preference-settings.ui.default-workflow-editor-dialog.new-editor-radio">
								<Radio isChecked={selectedWorkflowEditor === NEW_EDITOR} ariaLabel="New editor radio" />
								<RadioIcon src={newEditorImage} alt="new editor" />

								<FormattedMessage {...messages.newWorkflowEditor} />
							</Inline>
						</Box>
						<Box xcss={boxStyles} onClick={onSelectOld} padding="space.200" backgroundColor={selectedWorkflowEditor === LEGACY_EDITOR ? 'color.background.input.hovered' : 'color.background.neutral.subtle'}>
							<Inline alignBlock="center">
								<Radio isChecked={selectedWorkflowEditor === LEGACY_EDITOR} ariaLabel="Old editor radio" />
								<RadioIcon src={oldEditorImage} alt="old editor" />
								<FormattedMessage {...messages.oldWorkflowEditor} />
							</Inline>
						</Box>
					</Inline>
					{shouldShowWarningMessage && <WarningContainer role="alert">
							<WarningIcon size="medium" label="Warning" primaryColor={`var(--ds-text-warning, ${colors.Y300})`} />
							<WarningText>
								{workflowPublicationStatus === WorkflowPublicationStatus.Draft && <FormattedMessage {...messages.draftUnpublishedWarning} />}
								{workflowPublicationStatus === WorkflowPublicationStatus.Dirty && <FormattedMessage {...messages.dirtyLossWarning} values={{
              workflowName: <b>{workflowName}</b>,
              update: <i>{formatMessage(messages.updateButton)}</i>,
              cancel: <i>{formatMessage(messages.cancelButton)}</i>
            }} />}
							</WarningText>
						</WarningContainer>}
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose} appearance="subtle">
						{formatMessage(messages.cancelButton)}
					</Button>
					<Button onClick={onConfirmButtonClicked} isDisabled={userPreference === selectedWorkflowEditor} appearance="primary" testId="workflow-editor-preference-settings.ui.default-workflow-editor-dialog.confirm-button">
						{formatMessage(messages.confirmButton)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>;
};
export const DefaultWorkflowEditorDialog = ({
  workflowName,
  projectType,
  onClose,
  onOpenDefaultEditorDialog,
  workflowId,
  workflowPublicationStatus = WorkflowPublicationStatus.None,
  shouldRedirectToDraftMode = true
}: Props) => {
  return <JSErrorBoundary id="jsm-error-boundary-default-workflow-editor-dialog" packageName="workflow-editor-preference-settings" teamName="jsd-shield" fallback="flag">
			<DefaultWorkflowEditorDialogInner workflowName={workflowName} projectType={projectType} onClose={onClose} onOpenDefaultEditorDialog={onOpenDefaultEditorDialog} workflowId={workflowId} workflowPublicationStatus={workflowPublicationStatus} shouldRedirectToDraftMode={shouldRedirectToDraftMode} />
		</JSErrorBoundary>;
};
const boxStyles = xcss({
  borderStyle: 'solid',
  borderRadius: 'border.radius.100',
  borderWidth: 'border.width',
  borderColor: 'color.border',
  paddingLeft: 'space.050',
  paddingRight: 'space.500',
  paddingTop: 'space.150',
  paddingBottom: 'space.150'
}); // eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard

const Info = forwardRef(({
  as: C = "div",
  style: __cmpls,
  ...__cmplp
}, __cmplr) => {
  return <C {...__cmplp} style={__cmpls} ref={__cmplr} className={ax(["_11c8qk37 _19pku2gc", __cmplp.className])} />;
}); // eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard

if (process.env.NODE_ENV !== 'production') {
  Info.displayName = 'Info';
}
const RadioIcon = forwardRef(({
  as: C = "img",
  style: __cmpls,
  ...__cmplp
}, __cmplr) => {
  return <C {...__cmplp} style={__cmpls} ref={__cmplr} className={ax(["_1bsb1epz _u5f3pxbi _19bvu2gc", __cmplp.className])} />;
}); // eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard

if (process.env.NODE_ENV !== 'production') {
  RadioIcon.displayName = 'RadioIcon';
}
const Instruction = forwardRef(({
  as: C = "div",
  style: __cmpls,
  ...__cmplp
}, __cmplr) => {
  return <C {...__cmplp} style={__cmpls} ref={__cmplr} className={ax(["_11c8qk37 _ca0qpxbi _n3tdu2gc _syaz1rc1 _k48p1wq8", __cmplp.className])} />;
}); // eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard

if (process.env.NODE_ENV !== 'production') {
  Instruction.displayName = 'Instruction';
}
const WarningContainer = forwardRef(({
  as: C = "div",
  style: __cmpls,
  ...__cmplp
}, __cmplr) => {
  return <C {...__cmplp} style={__cmpls} ref={__cmplr} className={ax(["_1e0c1txw _ca0qu2gc _u5f3idpf _n3tdu2gc _19bvidpf", __cmplp.className])} />;
}); // eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard

if (process.env.NODE_ENV !== 'production') {
  WarningContainer.displayName = 'WarningContainer';
}
const WarningText = forwardRef(({
  as: C = "div",
  style: __cmpls,
  ...__cmplp
}, __cmplr) => {
  return <C {...__cmplp} style={__cmpls} ref={__cmplr} className={ax(["_18u0u2gc _16jlkb7n _syaz1w44", __cmplp.className])} />;
});
if (process.env.NODE_ENV !== 'production') {
  WarningText.displayName = 'WarningText';
}