import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import { di } from 'react-magnetic-di';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import { STATUS_CATEGORY_WEIGHTS } from './constants.tsx';
import type { AvailableRule, Rule, Status } from './types.tsx';

export const orderStatusesByCategoryAndName = (statuses: Status[]): Status[] =>
	sortBy(statuses, [(status) => STATUS_CATEGORY_WEIGHTS[status.statusCategory], 'name']);

export const getLegacyEditorLink = (workflowName: string) =>
	`/secure/admin/workflows/WorkflowDesigner.jspa?workflowMode=live&wfName=${encodeURIComponent(
		workflowName,
	)}`;

export const getNewEditorLink = (workflowId: string) =>
	`/jira/settings/issues/workflows/${workflowId}`;

export const openLegacyEditor = (workflowName: string) => {
	di(window);
	const url = getLegacyEditorLink(workflowName);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.assign(url);
};

export const buildEditWorkflowJSPUrl = (workflowName: string) =>
	`/secure/admin/workflows/EditWorkflowDispatcher.jspa?${queryString.stringify({
		atl_token: getXsrfToken(),
		wfName: workflowName,
	})}`;

export const conditionallyNarrowType = <A, B = unknown>(
	value: A | B,
	condition: boolean,
): value is A => condition;

export const findRuleDefinitionForConfiguredRule = (
	rule: Rule,
	availableRules: AvailableRule[],
): AvailableRule | undefined => {
	const ruleKey = rule.ruleKey;

	if (ruleKey.startsWith('connect')) {
		return availableRules.find(
			(definition) =>
				definition.variety === 'connect' &&
				definition.key === ruleKey &&
				definition.id === rule.config.appKey,
		);
	}

	if (ruleKey.startsWith('forge')) {
		return availableRules.find(
			(definition) =>
				definition.variety === 'forge' &&
				definition.key === ruleKey &&
				definition.id === rule.config.key,
		);
	}

	return availableRules.find((definition) => definition.key === ruleKey);
};
